import Client from './client';
import ScoreManager from './scoreManager';

const interval = 120 * 1000;

export default class AppEngine {
	#client = false;
	#scoreManager = false;
	#timeoutId = false;

	constructor() {
		this.#client = new Client(this);
		this.#scoreManager = new ScoreManager(this);
		//this.#scoreManager.deleteAllScoreData();
	}

	#doMaintenance = async () => {
		await this.#syncGames();
		this.#timeoutId = setTimeout(this.#doMaintenance, interval);
	}

	#startMaintenance = () => {
		this.#stopMaintenance();
		this.#doMaintenance();
	}

	#stopMaintenance = () => {
		if(this.#timeoutId) clearTimeout(this.#timeoutId);
	}

	#syncGames = async () => {
		const profileId = this.client.profileId;
		if(!profileId) return false;

		const allGames = {};

		const remoteGames = await this.client.listGames();
		remoteGames.forEach((game) => {
			allGames[game.id] = {
				id: game.id,
				mode: game.mode,
				score: game.score,
				date: game.date,
				_update: 'local',
			};
		});

		const localGames = await this.scoreManager.getGameLogForProfile(profileId);
		for(let guid in localGames) {
			const game = localGames[guid];
			if(allGames[guid]) {
				const remoteDate = new Date(allGames[guid].date);
				const localDate = new Date(game.date);
				if(localDate.getTime() == remoteDate.getTime()) {
					//No need to update
					allGames[guid]._update = false;
					continue;
				} else if(localDate.getTime() < remoteDate.getTime()) {
					//Use remote
					continue;
				}
			}

			//Use local
			allGames[guid] = {
				id: guid,
				mode: game.mode,
				score: game.score,
				date: game.date,
				_update: 'remote',
			};
		}

		let rateLimit = false;
		for(let id in allGames) {
			const game = allGames[id];
			if(game._update == 'local') this.scoreManager.setScore(profileId, id, game.mode, game.score, game.date);
			if(game._update == 'remote' && !rateLimit) {
				rateLimit = true;
				this.client.setScore(id, game.mode, game.score, game.date);
			}
		}

		return true;
	}

	get client() {
		return this.#client;
	}

	get scoreManager() {
		return this.#scoreManager;
	}

	profileIdChanged = () => {
		this.client.profileId ? this.#startMaintenance() : this.#stopMaintenance();
	};
}