import React, { useContext, useState } from 'react';
import { SafeAreaView, StyleSheet, Text, TextInput, View } from "react-native";
import { NavigationButton } from '../components';
import { Context } from '../../context';
import scaler from '../ui_utils/useScaler';

export const Profile = ({ navigation }) => {
	const { clientState, clientController } = useContext(Context);
	const [newPassword, setNewPassword] = useState('');
	const [verifyPassword, setVerifyPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState(false);
	const [waiting, setWaiting] = useState(false);

	const setValue = (setter, value) => {
		setErrorMessage(false);
		setter(value);
	};

	const handlePasswordUpdate = async () => {
		if (newPassword != verifyPassword) {
			setErrorMessage('Passwords must match.');
		} else {
			setWaiting(true);
			await clientController.changePassword(newPassword);
			setErrorMessage(clientState.lastError);
			setWaiting(false);
		}
		setNewPassword('');
		setVerifyPassword('');
	};

	const profile = {
		loggedIn: clientState.authenticated,
		username: clientState.profile ? clientState.profile.profile_display_name : '',
		firstName: clientState.profile ? clientState.profile.first_name : '',
		lastName: clientState.profile ? clientState.profile.last_name : '',
		email: clientState.profile ? clientState.profile.email_provided : '',
	};

	const styles = StyleSheet.create({
		notLoggedIn : {
			display: profile.loggedIn ? 'none' : 'flex',
		},

		passwordError : {
			display: errorMessage ? 'flex' : 'none',
		},
	});

	return (
		<View style={scaler.styles.window}>
			<SafeAreaView style={scaler.styles.page}>
				<View style={scaler.styles.column}>

					<Text style={scaler.styles.textTitle}>Tairedown™</Text>
					<Text style={scaler.styles.textSubtitle}>Your Profile</Text>

					<Text style={[scaler.styles.textBold, styles.notLoggedIn]}>You are not logged in.</Text>

					<View style={scaler.styles.form}>
						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>Username</Text>
							<Text style={scaler.styles.formValue}>{profile.username}</Text>
						</View>

						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>First name</Text>
							<Text style={scaler.styles.formValue}>{profile.firstName}</Text>
						</View>

						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>Last name</Text>
							<Text style={scaler.styles.formValue}>{profile.lastName}</Text>
						</View>

						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>Email</Text>
							<Text style={scaler.styles.formValue}>{profile.email}</Text>
						</View>
					</View>

					<View style={scaler.styles.formRow}>
						<Text style={scaler.styles.textSubtitle}>Change Password</Text>
					</View>

					<View style={scaler.styles.form}>

						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>New</Text>
							<TextInput
								disabled={waiting || !profile.loggedIn}
								onChangeText={(value) => { setValue(setNewPassword, value); }}
								style={scaler.styles.formInput}
								placeholder="New Password"
								secureTextEntry
								value={newPassword}
							/>
						</View>
						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>Verify</Text>
							<TextInput
								disabled={waiting || !profile.loggedIn}
								onChangeText={(value) => { setValue(setVerifyPassword, value); }}
								style={scaler.styles.formInput}
								placeholder="Verify Password"
								secureTextEntry
								value={verifyPassword}
							/>
						</View>
					</View>
					<View style={scaler.styles.row}>
						<Text style={[scaler.styles.formError, styles.passwordError]}>{errorMessage}</Text>
					</View>


					<View style={scaler.styles.row}>
						<NavigationButton
							onPress={() => { navigation.navigate('Home'); }}
							text="Home"
							type='muted'
						/>
						<NavigationButton
							disabled={waiting || !newPassword || !verifyPassword}
							onPress={handlePasswordUpdate}
							text="Update"
							type='success'
						/>
					</View>
				</View>
			</SafeAreaView>
		</View>
	);
}

export default Profile;