import { suits } from '../../definitions';

export default class Card {
	#suit = suits.spades;
	#rank = 'A';
	#value = 13;

	constructor (params) {
		if(params.suit) this.suit = params.suit;
		if(params.rank) this.rank = params.rank;
		if(params.value || params.value === 0) this.value = params.value;
	}

	getDisplay = () => {
		return this.rank + this.icon;
	};

	getLongDisplay = () => {
		return `${this.rank} of ${this.suit.display}`;
	};

	getState = () => {
		return {
			color: this.color,
			icon : this.icon,
			rank : this.rank,
			value : this.value
		};
	};

	//Getters
	get color () {
		return this.#suit.color;
	}

	get icon () {
		return this.#suit.icon;
	}

	get rank () {
		return this.#rank;
	}

	get suit () {
		return this.#suit;
	}

	get value () {
		return this.#value;
	}

	//Setters
	set rank (rank) {
		this.#rank = rank;
		return true;
	}

	set suit (suit) {
		this.#suit = suit;
		return true;
	}

	set value (value) {
		this.#value = value;
		return true;
	}
};