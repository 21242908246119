import { generateGuid } from '../../utils';
import { gameModes } from '../../definitions';
import Deck from './deck';

export default class Game {
	#guid = generateGuid();
	#gameMode = false;
	#side = false;
	#deck = false;
	#columns = [];
	#hasValidMove = false;

	constructor(gameMode) {
		this.#gameMode = gameModes[gameMode];
		if (!this.#gameMode) throw new Error(`Invalid Game Mode "${gameMode}"`);

		this.#columns.length = 0;
		for (let i = 0; i < this.#gameMode.column_count; i++) {
			this.#columns.push({ selected: false, cards: [] });
		}

		this.#deck = new Deck(this.#gameMode.deckType);
		this.#deck.shuffle();

		this.dealNextRow();
		this.unselectAll();
	}

	get cardsRemaining() {
		return this.#deck.cardsRemaining();
	}

	get columns() {
		return [...this.#columns];
	}

	get deckSize() {
		return this.#deck.deckSize();
	}

	get equation() {
		const equation = {
			left: [],
			right: [],
			side: this.#side
		}

		this.#columns.forEach((column) => {
			if (column.selected && column.cards.length > 0) {
				equation[column.selected].push(column.cards[column.cards.length - 1]);
			}
		});
		return equation;
	}

	get gameMode() {
		return this.#gameMode.key;
	}

	get gameName() {
		return this.#gameMode.display;
	}

	get guid() {
		return this.#guid;
	};

	get hasValidMove() {
		return this.#hasValidMove;
	}

	get score() {
		let score = this.#deck.cardsRemaining();
		this.#columns.forEach((column) => {
			score += column.cards.length;
		});

		return score;
	}

	get showValues() {
		return this.#gameMode.showValues === true;
	}

	get rowsRemaining() {
		return Math.floor(this.cardsRemaining / this.#columns.length);
	}

	clear = () => {
		if (this.equationIsValid()) {
			this.#columns.forEach((column) => {
				if (column.selected) {
					column.cards.pop();
				}
			});
			this.#calculateHasValidMove();
			this.unselectAll();
			return true;
		}

		this.unselectAll();
		return false;
	}

	dealNextRow = () => {
		if (this.rowsRemaining <= 0) return false;

		this.#columns.forEach((column) => {
			this.#deck.deal();
			column.selected = false;
			column.cards.push(this.#deck.currentCard());
		});

		this.#calculateHasValidMove();
		this.unselectAll();

		return true;
	}

	equationIsValid = () => {
		const equation = this.equation;

		if (!equation.left.length || !equation.right.length) return false;

		let leftTotal = 0;
		let rightTotal = 0;

		equation.left.forEach((card) => {
			leftTotal += card.value;
		});

		equation.right.forEach((card) => {
			rightTotal += card.value;
		});

		return leftTotal == rightTotal;
	};

	#calculateHasValidMove = () => {
		//Get the last cards' values for each column
		const values = [];
		this.#columns.forEach((column) => {
			const cards = column.cards;
			if (cards.length > 0) values.push(cards[cards.length - 1].value);
		});

		//Get every combination of values
		const getCombos = (a) => {
			const combos = [];
			a.forEach((item, index) => {
				const subset = a.toSpliced(index, 1);
				combos.push([item]);
				if (subset.length > 0) {
					const subCombos = getCombos(subset);
					subCombos.forEach((sub) => {
						combos.push([item].concat(sub));
					});
				}
			});
			return combos;
		}
		const combinations = getCombos(values);

		//For each combination, move the equals sign from left to right to create an eqation
		//Check each equation to see if there's a balanced one
		for (let c in combinations) {
			const combination = combinations[c];
			for (let i in combination) {
				if (i == combination.length) continue;
				let left = 0;
				let right = 0;
				for (let j in combination) {
					const value = combination[j];
					if (j > i) right += value; else left += value;
				}
				if (left == right) {
					this.#hasValidMove = true;
					return true;
				}
			}
		}

		this.#hasValidMove = false;
		return false;
	};

	toggleColumn = (index) => {
		if (this.#columns[index].cards.length == 0) {
			this.#columns[index].selected = false;
			return true;
		}

		const sideFull = this.equation[this.#side].length > this.#gameMode.column_count - 1;

		if (sideFull || this.#columns[index].selected == this.#side) {
			this.#columns[index].selected = false;
			return true;
		}

		this.#columns[index].selected = this.#side;

		return true;
	}

	toggleEquation = (side) => {
		if (side) {
			this.#side = side;
			return;
		}

		if (this.#side == 'left') {
			this.#side = 'right';
		} else {
			this.#side = 'left';
		}

		return true;
	}

	unselectAll = () => {
		this.#side = 'left';
		this.#columns.forEach((column) => {
			column.selected = false;
		});

		return true;
	}
};