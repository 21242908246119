import suits from './suits';

export const decks = {
	standard: {
		name : 'standard',
		display: 'Standard',
		suits : [suits.spades, suits.hearts, suits.diamonds, suits.clubs],
		ranks: ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'],
		values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
	},
	double: {
		name : 'double',
		display: 'Double Decker',
		suits : [suits.spades, suits.hearts, suits.diamonds, suits.clubs, suits.spades, suits.hearts, suits.diamonds, suits.clubs],
		ranks: ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'],
		values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
	},
	aceHigh: {
		name : 'aceHigh',
		display: 'Ace High',
		suits : [suits.spades, suits.hearts, suits.diamonds, suits.clubs],
		ranks: ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'],
		values: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
	},
	faceTen: {
		name : 'faceTen',
		display: 'Face 10',
		suits : [suits.spades, suits.hearts, suits.diamonds, suits.clubs],
		ranks: ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'],
		values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 10, 10, 10],
	},
	wizard: {
		name : 'wizard',
		display: 'Wizard',
		suits : [suits.spades, suits.hearts, suits.circles, suits.squares, suits.diamonds, suits.clubs],
		ranks: ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'W'],
    	values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
	},
	numbersOnly: {
		name : 'numbersOnly',
		display: '#s Only',
		suits : [suits.spades, suits.hearts, suits.diamonds, suits.clubs],
		ranks: ['2', '3', '4', '5', '6', '7', '8', '9', '10'],
    	values: [2, 3, 4, 5, 6, 7, 8, 9, 10],
	},
	fiveSuit: {
		name : 'fiveSuit',
		display: 'Five Suit',
		suits : [suits.spades, suits.hearts, suits.squares, suits.diamonds, suits.clubs],
		ranks: ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'],
		values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
	},
	jokers: {
		name : 'jokers',
		display: 'Jokers',
		suits : [suits.spades, suits.hearts, suits.diamonds, suits.clubs],
		ranks: ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'],
		values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
		add: [{rank: '🤡', suit: suits.red, value: 0}, {rank: '🤡', suit: suits.red, value: 0}]
	},
};

for(let deckType in decks) {
	const deck = decks[deckType];
	deck.size = deck.suits.length * deck.ranks.length + (deck.add ? deck.add.length : 0);
}

Object.freeze(decks);

export default decks;