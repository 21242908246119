/*
Properties:
	width - The width of the card in percent, e.g. 12.5 for 1/8th or 12.5% of the screen
	side - which side (if any) is selected
	left and right - An array of the the cards to include:
		color - The primary color for the card, e.g. red, black
		rank - The card rank, e.g. K, Q, J, 10, 9, etc.
		icon - The icon for the suit, e.g. ♠, ♥, etc.
		value - The card's value, e.g. 9, 10, 11, 12 for 9, 10, J, Q
	display - [none, icon, rank, value] What to render in the center of the card
	onPressLeft and onPressRight - The function to call when a card on the left or right is pressed
	onPressEquals - The function to call when the equals sign is pressed
*/

import { StyleSheet, View } from 'react-native';
import { Card } from './Card';

export const Equation = (props) => {
	const display = props.display || 'icon';
	const width = props.width || 12.5;
	const side = props.side;

	const onPressEquals = typeof (props.onPressEquals) == 'function' ? props.onPressEquals : () => {/*Do nothing*/ };
	const onPressLeft = typeof (props.onPressLeft) == 'function' ? props.onPressLeft : () => {/*Do nothing*/ };
	const onPressRight = typeof (props.onPressRight) == 'function' ? props.onPressRight : () => {/*Do nothing*/ };

	const left = props.left || [];
	const right = props.right || [];
	if(left.length == 0) left.push({empty:true});
	if(right.length == 0) right.push({empty:true});

	const emptyBackground = 'grey';
	const unselectedOpacity = 0.3;

	const styles = StyleSheet.create({

		equationContainer: {
			alignContent: 'center',
			display: 'flex',
			flex: 1,
			flexDirection: 'row',
			justifyContent: 'center',
			width: '100%',
		},
	});
	
	return (
		<View style={styles.equationContainer}>
			{left.map((card, i) => (
				<Card 
					backgroundColor={card.empty ? emptyBackground : 'white'}
					color={ card.color || 'black'}
					display={ display } 
					icon={ card.icon || ''}
					key={i}
					onPress={ () => { onPressLeft(i); }}
					opacity={ side == 'right' ? unselectedOpacity : 1 }
					rank={ card.rank || ''}
					width={ width } 
					value={ card.value === 0 ? 0 : (card.value || '')}
				/>
			))}

			<Card 
				backgroundColor="rgba(0, 0, 0, 0)"
				borderColor="rgba(0,0,0,0)"
				display="value"
				icon=""
				onPress={ () => { onPressEquals(); }}
				rank=""
				value="="
				width={ width } 
			/>

			{right.map((card, i) => (
				<Card 
					backgroundColor={card.empty ? emptyBackground : 'white'}
					color={ card.color || 'black'}
					display={ display } 
					icon={ card.icon || ''}
					key={i}
					onPress={ () => { onPressRight(i); }}
					opacity={ side == 'left' ? unselectedOpacity : 1 }
					rank={ card.rank || ''}
					width={ width } 
					value={ card.value || ''}
				/>
			))}
		</View>
	);
};

export default Equation;