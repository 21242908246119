import React, { useContext, useEffect, useState } from 'react';
import { Linking, SafeAreaView, StyleSheet, Text, TextInput, View } from "react-native";
import { NavigationButton } from '../components';
import { Context } from '../../context';
import scaler from '../ui_utils/useScaler';

export const Register = ({ navigation }) => {
	const { clientState, clientController } = useContext(Context);
	const [username, setUsername] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [verifyPassword, setVerifyPassword] = useState('');
	const [waiting, setWaiting] = useState(false);

	useEffect(() => {
		clientController.clearError();
	}, []);

	const handleRegister = async () => {
		setWaiting(true);
		const success = await clientController.register(username, password, firstName, lastName, email);
		setPassword('');
		setVerifyPassword('');
		setWaiting(false);
		if(success) navigation.navigate('Profile');
	};

	const formReady = () => {
		return username && firstName && lastName && email && password && (password == verifyPassword);
	};

	const styles = StyleSheet.create({

	});

	return (
		<View style={scaler.styles.window}>
			<SafeAreaView style={scaler.styles.page}>
				<View style={scaler.styles.column}>

					<Text style={scaler.styles.textTitle}>Tairedown™</Text>
					<Text style={scaler.styles.textSubtitle}>Register</Text>
					<View style={scaler.styles.form}>
						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>Username</Text>
							<TextInput
								onChangeText={setUsername}
								placeholder="Username"
								readOnly={waiting}
								style={scaler.styles.formInput}
								value={username}
							/>
						</View>

						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>First Name</Text>
							<TextInput
								onChangeText={setFirstName}
								placeholder="First name"
								readOnly={waiting}
								style={scaler.styles.formInput}
								value={firstName}
							/>
						</View>

						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>Last Name</Text>
							<TextInput
								onChangeText={setLastName}
								placeholder="Last name"
								readOnly={waiting}
								style={scaler.styles.formInput}
								value={lastName}
							/>
						</View>

						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>Email Address</Text>
							<TextInput
								onChangeText={setEmail}
								placeholder="email@address.com"
								readOnly={waiting}
								style={scaler.styles.formInput}
								value={email}
							/>
						</View>

						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>Password</Text>
							<TextInput
								onChangeText={setPassword}
								placeholder="Password"
								readOnly={waiting}
								secureTextEntry
								style={scaler.styles.formInput}
								value={password}
							/>
						</View>

						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>Verify Password</Text>
							<TextInput
								onChangeText={setVerifyPassword}
								placeholder="Verify Password"
								readOnly={waiting}
								secureTextEntry
								style={scaler.styles.formInput}
								value={verifyPassword}
							/>
						</View>

						<Text style={scaler.styles.textError}>{clientState.lastError ? `${clientState.lastError}` : ``}</Text>

						<Text style={scaler.styles.text}>By registering, you agree to the</Text>
						<Text 
							style={scaler.styles.textLink}
							onPress={() => {Linking.openURL('https://www.tairedown.com/terms');}}
						>
							Tairedown Terms of Service
						</Text>
					</View>

					<View style={scaler.styles.row}>
						<NavigationButton
							onPress={() => { navigation.navigate('Home'); }}
							text="Home"
							type='menu'
						/>
						<NavigationButton
							disabled={!formReady()}
							onPress={handleRegister}
							text="Register"
							type='menu'
						/>
					</View>
				</View>
			</SafeAreaView>
		</View>
	);
}

export default Register;