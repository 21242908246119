import React, { useContext } from 'react';
import { ImageBackground, SafeAreaView, StyleSheet, Text, View } from "react-native";
import { NavigationButton } from '../components';
import { Context } from '../../context';
import scaler from '../ui_utils/useScaler';
import backgroundImage from '../../assets/menu_background.png';

export const Home = ({ navigation }) => {
	const { scoreState, gameState, clientState, gameController, modalController } = useContext(Context);

	const startNewGame = (mode) => {
		gameController.newGame(mode);
		navigation.navigate('Game');
	}

	const requestNewGame = (mode) => {
		if(gameState.gameOver) {
			startNewGame(mode);
		} else {
			modalController.confirmDanger(
				'Start a new game?',
				'If you click "OK" you will lose any progress in your current game.',
				(result)=>{
					if(result) startNewGame(mode);
				}
			);
		}
	}

	const styles = StyleSheet.create({
		mainTitleSpacer: {
			display: 'flex',
			flexDirection: 'row',
			height: scaler.fontSize * 10,
			width: '100%',
		},

		variationSpacer: {
			display: 'flex',
			flexDirection: 'row',
			height: scaler.fontSize * 2,
			width: '100%',
		},

		menuTitle: {
			marginTop: scaler.fontSize * 0.75,
		},
	});

	return (
		<View style={scaler.styles.window}>
			<ImageBackground source={backgroundImage} style={scaler.styles.image}>
				<SafeAreaView style={[scaler.styles.page, scaler.styles.transparent]}>

					<View style={scaler.styles.column}>

						<Text style={styles.mainTitleSpacer}></Text>

						<View style={scaler.styles.row}>
							<NavigationButton
								onPress={() => { requestNewGame('standard'); }}
								text="New Game"
								type='menu'
							/>
							<NavigationButton
								disabled={!gameState.canResume}
								onPress={() => { navigation.navigate('Game'); }}
								text="Resume"
								type='menu'
							/>
						</View>

						<Text style={styles.variationSpacer}></Text>

						<Text style={[scaler.styles.textTitle, styles.menuTitle]}>Game Variations</Text>
						<View style={scaler.styles.row}>
							<NavigationButton
								onPress={() => { requestNewGame('aceHigh'); }}
								text="Ace High"
								type='menu'
							/>
							<NavigationButton
								onPress={() => { requestNewGame('numbersOnly'); }}
								text="Only Numbers"
								type='menu'
							/>
							<NavigationButton
								onPress={() => { requestNewGame('faceTen'); }}
								text="Face Ten"
								type='menu'
							/>
						</View>

						<Text style={[scaler.styles.textTitle, styles.menuTitle]}>Longer Games</Text>
						<View style={scaler.styles.row}>
							<NavigationButton
								onPress={() => { requestNewGame('wizard'); }}
								text="Wizard: 4 Columns"
								type='menu'
							/>
							<NavigationButton
								onPress={() => { requestNewGame('wizard6'); }}
								text="Wizard: 6 Columns"
								type='menu'
							/>

						</View>

						<View style={scaler.styles.row}>
							<NavigationButton
								onPress={() => { requestNewGame('fiveSuit'); }}
								text="Five Suit"
								type='menu'
							/>
							<NavigationButton
								onPress={() => { requestNewGame('double'); }}
								text="Double Deck"
								type='menu'
							/>
						</View>

						<Text style={[scaler.styles.textTitle, styles.menuTitle]}>Your Scores</Text>
						<Text style={scaler.styles.text}>{!clientState.authenticated ? 'Login to track scores.' : ''}</Text>
						<View style={scaler.styles.form}>
							<View style={scaler.styles.formRow}>
								<Text style={scaler.styles.formLabel}>Classic:</Text>
								<Text style={scaler.styles.formValue}>{`${scoreState.standard ? scoreState.standard.summary : 'Loading...'}`}</Text>
							</View>
							<View style={scaler.styles.formRow}>
								<Text style={scaler.styles.formLabel}>Ace High:</Text>
								<Text style={scaler.styles.formValue}>{`${scoreState.aceHigh ? scoreState.aceHigh.summary : 'Loading...'}`}</Text>
							</View>
							<View style={scaler.styles.formRow}>
								<Text style={scaler.styles.formLabel}>Only Numbers:</Text>
								<Text style={scaler.styles.formValue}>{`${scoreState.numbersOnly ? scoreState.numbersOnly.summary : 'Loading...'}`}</Text>
							</View>
							<View style={scaler.styles.formRow}>
								<Text style={scaler.styles.formLabel}>Face Ten:</Text>
								<Text style={scaler.styles.formValue}>{`${scoreState.faceTen ? scoreState.faceTen.summary : 'Loading...'}`}</Text>
							</View>
							<View style={scaler.styles.formRow}>
								<Text style={scaler.styles.formLabel}>Wizard 4:</Text>
								<Text style={scaler.styles.formValue}>{`${scoreState.wizard ? scoreState.wizard.summary : 'Loading...'}`}</Text>
							</View>
							<View style={scaler.styles.formRow}>
								<Text style={scaler.styles.formLabel}>Wizard 6:</Text>
								<Text style={scaler.styles.formValue}>{`${scoreState.wizard6 ? scoreState.wizard6.summary : 'Loading...'}`}</Text>
							</View>
							<View style={scaler.styles.formRow}>
								<Text style={scaler.styles.formLabel}>Five Suit:</Text>
								<Text style={scaler.styles.formValue}>{`${scoreState.fiveSuit ? scoreState.fiveSuit.summary : 'Loading...'}`}</Text>
							</View>
							<View style={scaler.styles.formRow}>
								<Text style={scaler.styles.formLabel}>Double Deck:</Text>
								<Text style={scaler.styles.formValue}>{`${scoreState.double ? scoreState.double.summary : 'Loading...'}`}</Text>
							</View>
						</View>

						<View style={scaler.styles.row}>
							<NavigationButton
								onPress={() => { navigation.navigate(clientState.authenticated ? 'Logout' : 'Login'); }}
								text={clientState.authenticated ? 'Logout' : 'Login'}
								type='menu'
							/>
							<NavigationButton
								onPress={() => { navigation.navigate('Profile'); }}
								text="Profile"
								type='menu'
							/>
							<NavigationButton
								onPress={() => { navigation.navigate('HowToPlay'); }}
								text="How to Play"
								type='menu'
							/>
						</View>

						<View style={scaler.styles.row}>
							<Text style={scaler.styles.textSmall}>© 2023-2024 Tairedown™</Text>
						</View>

					</View>
				</SafeAreaView>
			</ImageBackground>
		</View>
	);
}

export default Home;