import { Platform, StyleSheet, useWindowDimensions } from 'react-native';

class Scaler {
	#maxWidth = 640;
	#ratio = 2;
	#baseFontSize = 20;

	constructor() { };

	get scale() {
		return this.pageWidth / this.#maxWidth;
	}

	get fontSize() {
		return this.#baseFontSize * this.scale;
	}

	get pageHeight() {
		if (this.mobile) return this.windowHeight;
		return this.ratio * this.pageWidth;
	};

	get pageWidth() {
		if (this.mobile) return this.windowWidth;

		const windowWidth = this.windowWidth;
		const maxWidth = this.maxWidth;

		const fromWidth = windowWidth > maxWidth ? maxWidth : windowWidth;

		return fromWidth;
	};

	get mobile() {
		return Platform.OS !== 'web';
	};

	get maxWidth() {
		if (this.mobile) return this.windowWidth;
		return this.#maxWidth;
	};

	get ratio() {
		if (this.mobile) return this.windowHeight / this.windowWidth;
		return this.#ratio;
	};

	get windowHeight() {
		const { height } = useWindowDimensions();
		return height;
	};

	get windowWidth() {
		const { width } = useWindowDimensions();
		return width;
	};

	getHeight = (percentOfWidth) => {
		return (percentOfWidth / 100) * this.pageHeight / this.ratio;
	};

	getWidth = (percentOfWidth) => {
		return (percentOfWidth / 100) * this.pageWidth;
	};

	/** Common Styles **/

	get styles() {
		return StyleSheet.create({
			/*** Layout ***/
			window : {
				alignContent: 'start',
				backgroundColor: 'grey',
				display: 'grid',
				justifyContent: 'center',
				height: this.windowHeight > this.pageHeight ? this.windowHeight : this.pageHeight,
				width: this.windowWidth,
			},

			image: {
				height: this.pageHeight,
				width: this.pageWidth,
			},
			
			page: {
				alignContent: 'start',
				backgroundColor: 'white',
				display: 'grid',
				height: this.pageHeight,
				justifyContent: 'center',
				paddingTop: this.fontSize * 2,
				paddingBottom: this.fontSize,
				paddingHorizontal: this.fontSize,
				width: this.pageWidth,
			},

			transparent: {
				backgroundColor: 'none',
			},
	
			column: {
				alignItems: 'center',
				display: 'flex',
				height: '100%',
				flexDirection: 'column',
				flexWrap: 'nowrap',
				justifyContent: 'flex-start',
			},

			row: {
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'nowrap',
				justifyContent: 'space-evenly',
				width: '100%',
			},

			/*** Text ***/
			text: {
				display: 'flex',
				fontSize: this.fontSize,
				paddingVertical: this.fontSize * 0.25,
			},

			textBold: {
				display: 'flex',
				fontSize: this.fontSize,
				paddingVertical: this.fontSize * 0.5,
				fontWeight: '800'
			},

			textError: {
				color: 'red',
				display: 'flex',
				fontSize: this.fontSize,
				paddingVertical: this.fontSize * 0.25,
			},

			textLink: {
				color: 'rgb(33,150,243)',
				display: 'flex',
				fontSize: this.fontSize,
				paddingVertical: this.fontSize * 0.25,
				textDecorationLine: 'underline',
			},

			textSmall: {
				display: 'flex',
				fontSize: this.fontSize * 0.75,
				paddingVertical: this.fontSize * 0.25,
			},

			textSubtitle: {
				display: 'flex',
				fontSize: this.fontSize * 1.25,
				paddingVertical: this.fontSize * 0.5,
				fontWeight: '400'
			},

			textTitle: {
				display: 'flex',
				fontSize: this.fontSize * 1.75,
				paddingVertical: this.fontSize * 0.5,
				fontWeight: '400'
			},

			/*** Form ***/
			form: {
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				flexWrap: 'nowrap',
				justifyContent: 'space-evenly',
			},

			formError: {
				color: 'red',
				display: 'flex',
				fontSize: this.fontSize,
				margin: this.fontSize * 0.25,
				padding: this.fontSize * 0.25,
				width: this.getWidth(35),
			},

			formRow : {
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'nowrap',
				justifyContent: 'center',
				width: '100%',
			},

			formLabel: {
				alignItems: 'center',
				color: 'grey',
				display: 'flex',
				fontSize: this.fontSize,
				fontWeight: '800',
				justifyContent: 'flex-end',
				margin: this.fontSize * 0.25,
				padding: this.fontSize * 0.25,
				width: this.getWidth(27.5),
			},

			formValue: {
				display: 'flex',
				fontSize: this.fontSize,
				fontWeight: '800',
				margin: this.fontSize * 0.25,
				padding: this.fontSize * 0.25,
				width: this.getWidth(57.5),
			},

			formInput: {
				display: 'flex',
				fontSize: this.fontSize,
				borderWidth: 1,
				borderColor: '#ccc',
				borderRadius: this.fontSize * 0.25,
				margin: this.fontSize * 0.25,
				padding: this.fontSize * 0.25,
				width: this.getWidth(57.5),
			},
		});
	};
};

export default new Scaler();