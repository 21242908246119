export const gameModes = {
	standard : {
		column_count : 4,
		deckType : 'standard',
		display : 'Classic',
		key : 'standard',
		showValues : false
	},
	aceHigh : {
		column_count : 4,
		deckType : 'aceHigh',
		display : 'Ace High',
		key : 'aceHigh',
		showValues : true
	},
	faceTen : {
		column_count : 4,
		deckType : 'faceTen',
		display : 'Face Ten',
		key : 'faceTen',
		showValues : true
	},
	wizard : {
		column_count : 4,
		deckType : 'wizard',
		display : 'Wizard',
		key : 'wizard',
		showValues : true
	},
	wizard6 : {
		column_count : 6,
		deckType : 'wizard',
		display : '6 Column Wizard',
		key : 'wizard6',
		showValues : true
	},
	double : {
		column_count : 4,
		deckType : 'double',
		display : 'Double Deck',
		key : 'double',
		showValues : false
	},
	numbersOnly : {
		column_count : 4,
		deckType : 'numbersOnly',
		display : '#s Only',
		key : 'numbersOnly',
		showValues : false
	},
	fiveSuit : {
		column_count : 5,
		deckType : 'fiveSuit',
		display : '5 Suits',
		key : 'fiveSuit',
		showValues : false
	},
	threeColumn: {
		column_count : 3,
		deckType : 'jokers',
		display : '3 Columns',
		key : 'threeColumn',
		showValues : true
	},
};

Object.freeze(gameModes);

export default gameModes;