/*
Properties:
	disabled - The button cannot be pressed
	onPress - The function to call when the card is pressed
	opacity - How opaque the button should be
	padding - The padding of the button in percent, e.g. 12.5 for 1/8th or 12.5% of the screen
	text - Text to be displayed
	type = The button type and how to render it
	width - The width of the button in percent, e.g. 12.5 for 1/8th or 12.5% of the screen
*/

import { Pressable, StyleSheet, Text, View } from 'react-native';
import scaler from '../ui_utils/useScaler';

export const NavigationButton = (props) => {
	const disabled = props.disabled === true;
	const onPress = typeof (props.onPress) == 'function' ? props.onPress : () => {/*Do nothing*/ };
	const text = props.text || '';
	const types = getTypes(disabled);
	const type = types[props.type] || types.primary;

	const ratio = 2 / 5;

	const widthPct = props.width || 25;
	const paddingPct = props.padding || 2;

	const overallWidth = scaler.getWidth(widthPct);
	const overallHeight = scaler.getHeight(widthPct * ratio);
	const padding = scaler.getWidth(paddingPct);

	const width = overallWidth - padding;
	const height = overallHeight - padding;
	const radius = width * 0.1;
	const fontSize = width * 0.11;

	const styles = StyleSheet.create({

		button: {
			alignItems: 'center',
			backgroundColor: type.background,
			borderColor: type.border ? type.border : '',
			borderRadius: radius,
			borderWidth: type.border ? 1 : 0,
			display: 'flex',
			flexDirection: 'row',
			height: height,
			justifyContent: 'center',
			opacity: props.opacity ? parseFloat(props.opacity) : 1,
			overflow: 'hidden',
			width: width,
		},

		container: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
			height: overallHeight,
			justifyContent: 'center',
			overflow: 'hidden',
			width: overallWidth,
		},

		text: {
			alignItems: 'center',
			color: type.color,
			flex: 1,
			fontSize: fontSize,
			fontWeight: '800',
			textAlign: 'center',
		},
	});

	return (
		<View style={styles.container}>
			<Pressable style={styles.button}
				disabled={disabled}
				onPress={() => { onPress(); }}
			>
				<Text style={styles.text}>{text}</Text>
			</Pressable>
		</View>
	);
};

export default NavigationButton;

const getTypes = (disabled) => {
	const transparency = 0.5;
	if(disabled) return {
		danger: {
			background: `rgba(217, 83, 79, ${transparency})`,
			border: false,
			color: 'white',
		},
		menu: {
			background: `rgba(20, 50, 40, ${transparency})`,
			border: `rgba(210, 170, 70, ${transparency})`,
			color: `rgba(210, 170, 70, ${transparency})`,
		},
		muted: {
			background: `rgba(255,255,255, ${transparency})`,
			border: `silver`,
			color: `dimgrey`,
		},
		info: {
			background: `rgba(70, 184, 218, ${transparency})`,
			border: false,
			color: 'white',
		},
		primary: {
			background: `rgba(46, 109, 164, ${transparency})`,
			border: false,
			color: 'white',
		},
		success: {
			background: `rgba(76, 174, 76, ${transparency})`,
			border: false,
			color: 'white',
		},
		warning: {
			background: `rgba(240, 173, 78, ${transparency})`,
			border: false,
			color: 'white',
		},	};

	return {
		danger: {
			background: 'rgb(217, 83, 79)',
			border: false,
			color: 'white',
		},
		menu: {
			background: 'rgb(20, 50, 40)',
			border: 'rgb(210, 170, 70)',
			color: 'rgb(210, 170, 70)',
		},
		muted: {
			background: 'white',
			border: 'dimgrey',
			color: 'dimgrey',
		},
		info: {
			background: 'rgb(70, 184, 218)',
			border: false,
			color: 'white',
		},
		primary: {
			background: 'rgb(46, 109, 164)',
			border: false,
			color: 'white',
		},
		success: {
			background: 'rgb(76, 174, 76)',
			border: false,
			color: 'white',
		},
		warning: {
			background: 'rgb(240, 173, 78)',
			border: false,
			color: 'white',
		},
	};
};