import { useState} from 'react';
import { Modal, StyleSheet, View, Text, TextInput } from 'react-native';
import { NavigationButton } from '../components/NavigationButton';
import scaler from '../ui_utils/useScaler';

export const AppModal = (props) => {
	const [promptValue, setPromptValue] = useState('');

	const buttons = props.buttons || [];
	const prompt = props.prompt === true;
	const text = props.text;
	const title = props.title;
	const visible = props.visible;

	const width = scaler.getWidth(75);
	const height = scaler.getHeight(50);
	const radius = width * 0.03;
	const padding = width * 0.02;

	const titleHeight = title ? height * 0.2 : 0;
	const buttonHeight = height * 0.2;
	const bodyHeight = height - titleHeight - buttonHeight;

	const handlePress = (func) => {
		const response = promptValue;
		setPromptValue('');
		func(response);
	};

	const boxWidth = '92.5%';

	const styles = StyleSheet.create({

		bodyBox: {
			borderBottomColor: 'silver',
			borderBottomWidth: 1,
			borderTopColor: 'silver',
			borderTopWidth: 1,
			height: bodyHeight,
			padding: padding,
			width: boxWidth,
		},

		buttonBox: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'nowrap',
			justifyContent: 'flex-end',
			height: buttonHeight,
			width: boxWidth,
		},

		modal: {
			alignItems: 'center',
			backgroundColor: 'white',
			borderRadius: radius,
			display: 'flex',
			flexDirection: 'column',
			height: height,
			justifyContent: 'flex-start',
			width: width,
		},

		overlay: {
			alignContent: 'start',
			display: 'grid',
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			height:'100%',
			justifyContent: 'center',
			paddingTop: '10%',
			width:'100%',
		},

		prompt: {
			borderWidth: 1,
			borderColor: '#ccc',
			borderRadius: scaler.fontSize * 0.25,
			fontSize: scaler.fontSize,
			display: prompt ? 'flex' : 'none',
			height: scaler.fontSize * 2.2,
			margin: scaler.fontSize * 0.25,
			padding: scaler.fontSize * 0.25,
			width: width * 0.9,
		},

		titleBox: {
			alignItems: 'center',
			display: title ? 'flex' : 'none',
			justifyContent: 'space-evenly',
			height: titleHeight,
			width: boxWidth,
		},

	});
	
	return (
		<Modal
			transparent={true}
			visible={visible}
		>
			<View style={styles.overlay}>
				<View style={styles.modal}>

					<View style={styles.titleBox}><Text style={scaler.styles.textSubtitle}>{title}</Text></View>
					
					<View style={styles.bodyBox}>
						<Text style={scaler.styles.text}>{text}</Text>
						<TextInput 
							onChangeText={setPromptValue}
							style={styles.prompt}
							value={promptValue}
						/>
					</View>

					<View style={styles.buttonBox}>
						{buttons.map((button, i) => (
							<NavigationButton
								disabled={prompt && promptValue == '' && button.type=='success'}
								key={ i }
								onPress={()=>{handlePress(button.onPress);}}
								text={button.text}
								type={button.type}
								width="20"
							/>
						))}
					</View>

				</View>

			</View>
		</Modal>
	);
};

export default AppModal;