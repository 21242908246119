/*
Properties:
	backgroundColor - The background color for the card
	borderColor - The border color for the card
	clip -  If true only the top of the card is rendered
	color - The primary color for the card, e.g. red, black
	display - [none, icon, rank, value] What to render in the center of the card
	icon - The icon for the suit, e.g. ♠, ♥, etc.
	onPress - The function to call when the card is pressed
	opacity - How opaque the card should be
	rank - The card rank, e.g. K, Q, J, 10, 9, etc.
	value - The card's value, e.g. 9, 10, 11, 12 for 9, 10, J, Q
	width - The width of the card in percent, e.g. 12.5 for 1/8th or 12.5% of the screen
*/

import { Pressable, StyleSheet, Text, View } from 'react-native';
import scaler from '../ui_utils/useScaler';

export const Card = (props) => {
	const clip = props.clip === true;
	const icon = props.icon || '';
	const onPress = typeof (props.onPress) == 'function' ? props.onPress : () => {/*Do nothing*/ };
	const rank = props.rank || '';
	const value = props.value === 0 ? 0 : (props.value || '');

	let display = icon;
	if (props.display) {
		display = '';
		if (props.display.toLowerCase() == 'icon') display = icon;
		if (props.display.toLowerCase() == 'rank') display = rank;
		if (props.display.toLowerCase() == 'value') display = value;
	}

	const ratio = 3/2;

	const overallWidth = (props.width / 100 || 0.125) * scaler.pageWidth;
	const overallHeight = overallWidth * ratio;

	const border = overallWidth * 0.04;
	const padding = border;
	const width = overallWidth - border;
	const height = overallHeight - border;
	const radius = overallWidth * 0.1;

	const fontSizeOuter = overallWidth * 0.2;
	const fontSizeMiddle = overallWidth * 0.45;

	const clippedHeight = clip ? overallHeight * 0.25 : overallHeight;

	const styles = StyleSheet.create({
		bottom: {
			transform: [{'rotate' : '180deg'}],
		},

		card: {
			backgroundColor: props.backgroundColor || 'white',
			borderColor: props.borderColor || 'black',
			borderRadius: radius,
			borderStyle: 'solid',
			borderWidth: border,
			display: 'flex',
			height: height,
			opacity: props.opacity ? parseFloat(props.opacity) : 1,
			overflow: 'hidden',
			width: width,
		},

		clipped: {
			height: clippedHeight,
			overflow: 'hidden',
		},

		middle: {
			color: props.color || 'black',
			fontSize: fontSizeMiddle,
			fontWeight: '800',
			height: '40%',
//			placeItems: 'center',
			textAlign: 'center',
			width: '100%',
		},

		outer: {
			color: props.color || 'black',
			fontSize: fontSizeOuter,
			fontWeight: '800',
			height: '30%',
			paddingLeft: padding,
			textAlign: 'left',
			width: '100%',
		},

		top: {

		},
	});

	return (
		<View style={styles.clipped}>
		<Pressable style={styles.card} onPress={() => { onPress(); }}>
			<Text style={[styles.outer, styles.top]}>{rank} {icon}</Text>
			<Text style={styles.middle}>{display}</Text>
			<Text style={[styles.outer, styles.bottom]}>{rank} {icon}</Text>
		</Pressable>
		</View>
	);
};

export default Card;