export const suits = {
	spades : {icon : '♠', display : 'Spades', color : 'black'},
	hearts : {icon : '♥', display : 'Hearts', color : 'red'},
	squares : {icon : '■', display : 'Squares', color : 'blue'},
	circles : {icon : '●', display : 'Circles', color : 'blue', reverse: true},
	diamonds : {icon : '♦', display : 'Diamonds', color : 'red', reverse: true},
	clubs : {icon : '♣', display : 'Clubs', color : 'black', reverse: true},
	red : {icon : '', display : 'Red', color : 'red'},
	black : {icon : '', display : 'Black', color : 'black'},
};

Object.freeze(suits);

export default suits;