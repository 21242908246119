import React, { useContext, useState } from 'react';
import { SafeAreaView, StyleSheet, Text, TextInput, View } from "react-native";
import { NavigationButton } from '../components';
import { Context } from '../../context';
import scaler from '../ui_utils/useScaler';

export const OneTimePasscode = ({ navigation }) => {
	const { clientState, clientController } = useContext(Context);
	const [username, setUsername] = useState('');
	const [otp, setOTP] = useState('');
	const [waiting, setWaiting] = useState(false);

	const handleOTP = async () => {
		setWaiting(true);
		setWaiting(false);
	};

	return (
		<View style={scaler.styles.window}>
			<SafeAreaView style={scaler.styles.page}>
				<View style={scaler.styles.column}>

					<Text style={scaler.styles.textTitle}>Tairedown™</Text>
					<Text style={scaler.styles.textSubtitle}>Login with a One Time Passcode</Text>
					
					<Text style={scaler.styles.text}>Enter your username to have the code sent to your email.</Text>


					<View style={scaler.styles.form}>

						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>Username</Text>
							<TextInput
								onChangeText={setUsername}
								placeholder="Username"
								readOnly={waiting}
								style={scaler.styles.formInput}
								value={username}
							/>
						</View>

						<NavigationButton
							disabled={waiting || !username}
							onPress={() => {}}
							text="Send Code"
							type='menu'
						/>

						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>Passcode</Text>
							<TextInput
								onChangeText={setOTP}
								placeholder="Passcode"
								readOnly={waiting}
								style={scaler.styles.formInput}
								value={otp}
							/>
						</View>

						<NavigationButton
							disabled={waiting || !otp}
							onPress={handleOTP}
							text="Enter"
							type='menu'
						/>

					</View>

					<View style={scaler.styles.row}>
						<NavigationButton
							onPress={() => { navigation.navigate('Home'); }}
							text="Home"
							type='menu'
						/>
					</View>
				</View>
			</SafeAreaView>
		</View>
	);
}

export default OneTimePasscode;