import { useEffect, useState } from 'react';
import { gameModes } from '../definitions';

export const useScoreManager = (engine) => {
	const scoreManager = engine.scoreManager;
	const [scoreState, setScoreState] = useState({});

	let _profileId = false;

	useEffect(() => {
		_initScoreManager();
	}, []);

	const _initScoreManager = async () => {
		await scoreManager.loadGameLog();
		updateClientState();
	}

	const updateClientState = () => {
		const profileGameLog = scoreManager.gameLog[_profileId] || {};

		const logByMode = {};
		for(let guid in profileGameLog) {
			const mode = profileGameLog[guid].mode;
			if(!logByMode[mode]) logByMode[mode] = [];
			logByMode[mode].push(profileGameLog[guid]);
		};
	
		const state = {};
	
		for(let key in gameModes) {
			const details = {
				display: gameModes[key].display,
				game_log: [],
				key: key,
				stats: {
					best: false,
					played: 0,
					total: 0,
					average: false,
				},
				summary: _profileId ? 'Not played' : ''
			};
			if(logByMode && logByMode[key]) details.game_log = logByMode[key];
	
			if(details.game_log.length) {
				details.stats.played = details.game_log.length;
				details.game_log.forEach((game)=> {
					details.stats.total += game.score;
					if(!details.stats.best || game.score < details.stats.best) details.stats.best = game.score;
				});
				details.stats.average = Math.round(10 * details.stats.total / details.stats.played) / 10;
				details.summary = `Best: ${details.stats.best} | Avg: ${details.stats.average.toFixed(1)} | Games: ${details.stats.played}`;
			}
	
			state[key] = details;
		}
	
		setScoreState(state);
	}

	const profileIdChanged = (profileId) => {
		_profileId = profileId;
		updateClientState();
	};

	const setScore = async (guid, mode, score) => {
		if(_profileId) await scoreManager.setScore(_profileId, guid, mode, score);
		updateClientState();
	};

	const deleteAllScoreData = async () => {
		await scoreManager.deleteAllScoreData();
		updateClientState();
	};

	return {
		scoreState,
		scoreController : {
			deleteAllScoreData,
			profileIdChanged,
			setScore,
			updateClientState
		}
	};
};

export default useScoreManager;