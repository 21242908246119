/*
Properties:
	cards - An array of the the cards to include:
		color - The primary color for the card, e.g. red, black
		rank - The card rank, e.g. K, Q, J, 10, 9, etc.
		icon - The icon for the suit, e.g. ♠, ♥, etc.
		value - The card's value, e.g. 9, 10, 11, 12 for 9, 10, J, Q
	display - [none, icon, rank, value] What to render in the center of the card
	onPress - The function to call when the column is pressed
	selected - True if selected
	width - The width of the cards in percent, e.g. 12.5 for 1/8th or 12.5% of the screen
*/

import { StyleSheet, View } from 'react-native';
import { Card } from './Card';

export const Column = (props) => {
	const cards = props.cards || [];
	const display = props.display || 'icon';
	const onPress = typeof (props.onPress) == 'function' ? props.onPress : () => {/*Do nothing*/ };
	const selected = props.selected;
	const width = props.width || 12.5;

	if(cards.length == 0) cards.push({opacity : 0.50});
	const borderColor = selected ? 'grey' : 'black';

	const styles = StyleSheet.create({
		columnContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
		},
	});
	
	return (
		<View style={styles.columnContainer}>
			{cards.map((card, i) => (
				<Card 
					borderColor={ borderColor }
					color={ card.color || 'black'}
					clip={ (i < cards.length - 1) }
					display={ display } 
					icon={ card.icon || ''}
					key={ i }
					onPress={ () => { onPress(i); }}
					opacity={ (i < cards.length - 1) ? 0.67 : (card.opacity || 1) }
					rank={ card.rank || ''}
					value={ card.value || ''}
					width={ width } 
				/>
			))}
		</View>
	);
};

export default Column;