import React, { useContext, useState } from 'react';
import { SafeAreaView, StyleSheet, Text, TextInput, View } from "react-native";
import { NavigationButton } from '../components';
import { Context } from '../../context';
import scaler from '../ui_utils/useScaler';

export const Logout = ({ navigation }) => {
	const { clientController } = useContext(Context);
	const [waiting, setWaiting] = useState(false);

	const handleLogout = async () => {
		setWaiting(true);
		const success = await clientController.logout();
		setWaiting(false);
		if(success) navigation.navigate('Home');
	};

	return (
		<View style={scaler.styles.window}>
			<SafeAreaView style={scaler.styles.page}>
				<View style={scaler.styles.column}>

					<Text style={scaler.styles.textTitle}>Tairedown™</Text>
					<Text style={scaler.styles.textSubtitle}>Logout</Text>

					<Text style={scaler.styles.text}>Are you sure you want to logout?</Text>

					<View style={scaler.styles.row}>
						<NavigationButton
							disabled={waiting}
							onPress={() => { navigation.navigate('Home'); }}
							text="Home"
							type='muted'
						/>
						<NavigationButton
							disabled={waiting}
							onPress={handleLogout}
							text="Logout"
							type='danger'
						/>
					</View>
				</View>
			</SafeAreaView>
		</View>
	);
}

export default Logout;