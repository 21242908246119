import AsyncStorage from '@react-native-async-storage/async-storage';

export default class ScoreManager {
	#gameLog = false;

	constructor() {}

	#saveGameLog = async () => {
		AsyncStorage.setItem('GameLog', JSON.stringify(this.#gameLog));	
		return true;
	}

	get gameLog() {
		return this.#gameLog;
	}

	deleteAllScoreData = async() => {
		this.#gameLog = {};
		await this.#saveGameLog();
		return true;
	}

	loadGameLog = async () => {
		this.#gameLog = {};
	
		const storedValue = await AsyncStorage.getItem('GameLog');
		if (!storedValue || storedValue === 'undefined') {
			return;
		}
	
		const gameLog = JSON.parse(storedValue);
		this.#gameLog = gameLog;
		return true;
	}

	getGameLogForProfile = async(profileId) => {
		await this.loadGameLog();
		if(this.gameLog[profileId]) return this.gameLog[profileId];
		return {};
	}

	setScore = async (profileId, guid, mode, score, date) => {
		await this.loadGameLog();

		if(!this.#gameLog[profileId]) this.#gameLog[profileId] = {};

		this.#gameLog[profileId][guid] = {
			guid: guid,
			mode: mode,
			score: score,
			date: date || new Date().toISOString(),
		};

		this.#saveGameLog();

		return true;
	}
}