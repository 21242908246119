import React, { useContext } from 'react';
import { SafeAreaView, ScrollView, StyleSheet, Text, View } from "react-native";
import { Context } from '../../context';
import { Equation, NavigationButton } from '../components';
import scaler from '../ui_utils/useScaler';

export const HowToPlay = ({ navigation }) => {
	const { version } = useContext(Context);

	const examples = [
		{
			title: 'Example 1',
			left: [
				{ color: 'black', icon: '♠', rank: 'A', value: '1' }
			],
			right: [
				{ color: 'red', icon: '♥', rank: 'A', value: '1' }
			],
		},
		{
			title: 'Example 2',
			left: [
				{ color: 'black', icon: '♣', rank: '2', value: '2' },
				{ color: 'red', icon: '♦', rank: '2', value: '2' }
			],
			right: [
				{ color: 'red', icon: '♥', rank: '4', value: '4' }
			],
		},
		{
			title: 'Example 3',
			left: [
				{ color: 'black', icon: '♠', rank: 'J', value: '11' },
				{ color: 'red', icon: '♥', rank: '5', value: '5' }
			],
			right: [
				{ color: 'red', icon: '♥', rank: 'K', value: '13' },
				{ color: 'black', icon: '♠', rank: '3', value: '3' },
			],
		},
		{
			title: 'Example 4',
			left: [
				{ color: 'red', icon: '♦', rank: 'K', value: '13' },
			],
			right: [
				{ color: 'black', icon: '♣', rank: 'A', value: '1' },
				{ color: 'black', icon: '♠', rank: '2', value: '2' },
				{ color: 'black', icon: '♠', rank: '10', value: '10' },
			],
		},
	];

	const styles = StyleSheet.create({
		scrollView: {
			marginHorizontal: 20,
		},

		example: {
			alignItems: 'flex-start',
			flexDirection: 'column',
			display: 'flex',
		},

		exampleContainer: {
			alignItems: 'flex-start',
			flexDirection: 'column',
			display: 'flex',
		},

		equationContainer: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-around',
		},

	});

	return (
		<View style={scaler.styles.window}>
			<SafeAreaView style={scaler.styles.page}>

				<View style={scaler.styles.column}>
					<Text style={scaler.styles.textTitle}>How to Play Tairedown™</Text>
					<View style={scaler.styles.row}>
						<NavigationButton
							onPress={() => { navigation.navigate('Home'); }}
							padding="5"
							text="Home"
							type='muted'
							width="30"
						/>
						<Text style={scaler.styles.textSmall}>{`v${version}`}</Text>
					</View>

					<ScrollView style={styles.scrollView}>

						<Text style={scaler.styles.textSubtitle}>
							Overview
						</Text>
						<Text style={scaler.styles.text}>
							Each deal plays one card in to each column.
						</Text>
						<Text style={scaler.styles.text}>
							Select a side of the equation then select a column, which will add last card from that column to the equation. Once you create a balanced equation, clear the cards away!
						</Text>
						<Text style={scaler.styles.text}>
							Your score is the number of cards remaining after you've dealt all the cards from the deck, and cleared as many as you can.
						</Text>

						<Text style={scaler.styles.textSubtitle}>
							Examples
						</Text>
						<View>
							{examples.map((example, i) => (
								<View style={styles.exampleContainer} key={i}>
									<Text style={scaler.styles.textBold}>
										{example.title}:
									</Text>
									<View style={styles.equationContainer}>
										<Equation
											left={example.left}
											right={example.right}
											display="value"
										/>
									</View>
								</View>
							))}
						</View>

						<Text style={scaler.styles.textSubtitle}>
							The Game Modes
						</Text>

						<Text style={scaler.styles.textBold}>
							Classic
						</Text>
						<Text style={scaler.styles.text}>
							The original game, played with a standard deck of 52 cards. The card values are 1 for an Ace through 13 for a King.
						</Text>

						<Text style={scaler.styles.textBold}>
							Ace High
						</Text>
						<Text style={scaler.styles.text}>
							This game is the same as Classic, but the Ace is has a value of 14 instead of 1 for a slightly different spin.
						</Text>

						<Text style={scaler.styles.textBold}>
							#s Only
						</Text>
						<Text style={scaler.styles.text}>
							Same as Classic, but the Aces, Jacks, Queens, and Kings have been removed from the deck. This makes for a quicker game.
						</Text>

						<Text style={scaler.styles.textBold}>
							Face Ten
						</Text>
						<Text style={scaler.styles.text}>
							Same as Classic, but all face cards have a value of 10 to mix it up.
						</Text>

						<Text style={scaler.styles.textBold}>
							Wizard & 6 Column Wizard
						</Text>
						<Text style={scaler.styles.text}>
							These are games with a special 84-card deck designed for Tairedown. It includes 6 suits and a Wizard card with a value of 14! This makes for a longer game and can be played with 4 or 6 columns.
						</Text>

						<Text style={scaler.styles.textBold}>
							Five Suit
						</Text>
						<Text style={scaler.styles.text}>
							Similar to Classic, but with 5 suits and 5 columns for 65 cards and a slightly longer game.
						</Text>

						<Text style={scaler.styles.textBold}>
							Double Deck
						</Text>
						<Text style={scaler.styles.text}>
							Just like Classic, but with two full-sized decks of 52 cards (104 total). It's the longest version of Tairedown.
						</Text>
					</ScrollView>
				</View>

			</SafeAreaView>
		</View>
	);
}

export default HowToPlay;