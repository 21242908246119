const fetch = require('node-fetch');
import AsyncStorage from '@react-native-async-storage/async-storage';

const apiEndpoint = 'https://pzjojihja0.execute-api.us-east-2.amazonaws.com';
const apiKey = '64627c98-411c-48d3-f2ed-95e96f96ea7e';

export default class Client{
	#authenticated = false;
	#profile = false;
	#session = false;

	constructor() {}

	get authenticated () {
		return this.#authenticated;
	}

	get profile () {
		return this.#profile;
	}

	get profileId () {
		if(!this.#profile && !this.#profile.id) return false;
		return this.#profile.id;
	}

	get session () {
		return this.#session;
	}

	get sessionKey () {
		if(!this.#session && !this.#session.id) return false;
		return this.#session.id;
	}

	#generateOptions = (method, body) => {
		const headers = {
			'Content-Type': 'application/json',
			'User-Agent': 'Tairedown/0.0.1',
			'Accept': '*/*',
			'Accept-Encoding': 'gzip, deflate, br',
			'Authorization': 'Bearer ' + apiKey,
		};
	
		if (this.sessionKey) headers['x-session-id'] = this.sessionKey;
	
		const options = {
			headers: headers,
			method: method,
		};
	
		if (body) options.body = JSON.stringify(body);
	
		return options;
	}

	#logout = () => {
		this.#authenticated = false;
		this.#profile = false;
		this.#session = false;	
	}

	#notLoggedIn = () => {
		this.#authenticated = false;
	}

	#saveClientData = async () => {
		const clientData = {
			profile: this.profile,
			session: this.session,
		};
		await AsyncStorage.setItem('ClientData', JSON.stringify(clientData));
		return true;
	}

	#setProfile = (profile) => {
		if (!profile.active) throw new Error(`Cannot set Profile, Profile is inactive.`);
		this.#profile = profile;
	}

	#setSession = (session) => {
		this.#authenticated = false;
		this.#session = false;
	
		if (!this.profile) throw new Error(`Cannot set session, no Profile.`);
		if (session.profile_id != this.profile.id) throw new Error(`Cannot set session, does not match Profile.`);;
		const expires = new Date(session.expires);
		if (new Date().getTime() > expires.getTime()) throw new Error(`Cannot set session, Session has exipred.`);
	
		this.#authenticated = true;
		this.#session = session;
	}

	changePassword = async(password) => {
		const result = {
			success: false,
			message: 'Not logged in.',
		}

		if(!this.profile) {
			return result;
		}

		result.message = 'Error connecting to server.';

		const response = await fetch(
			`${apiEndpoint}/update_profile`,
			this.#generateOptions('PATCH', {password: password})
		);


		if (response.ok) {
			const data = await response.json();
			if (data.success) {
				result.success = true;
				result.message = '';
			} else {
				result.message = data.message;
			}
		}
		
		return result;
	}

	listGames = async() => {
		if(!this.profile) return [];

		const response = await fetch(
			`${apiEndpoint}/list_games`,
			this.#generateOptions('GET')
		);

		if (response.ok) {
			const data = await response.json();
			if (data.success && data.games) return data.games;
		}
		
		return [];
	}

	loadClientData = async () => {
		const storedValue = await AsyncStorage.getItem('ClientData');
		if (!storedValue || storedValue === 'undefined') {
			return;
		}
		const clientData = JSON.parse(storedValue);
		try {
			if (clientData.profile && clientData.session) {
				this.#setProfile(clientData.profile);
				this.#setSession(clientData.session);
			}
		} catch (e) {
			return false;
		}
		return true;
	}

	login = async (username, password) => {
		this.#logout();

		const response = await fetch(
			`${apiEndpoint}/login`,
			this.#generateOptions('POST', { profile_display_name: username, password: password })
		);

		const result = {
			success: false,
			message: 'Error connecting to server.',
		}

		if (response.ok) {
			const data = await response.json();
			if (data.success) {
				try {
					this.#setProfile(data.profile);
					this.#setSession(data.session);
					result.success = true;
					result.message = 'Logged in.';
				} catch (e) {
					result.message = e.message;
				}
			} else {
				result.message = data.message;
			}
		}
		
		this.#saveClientData();
		return result;
	}
	
	logout = () => {
		this.#logout();
		this.#saveClientData();
		return true;
	}

	register = async (username, password, firstName, lastName, email) => {
		this.#logout();

		const response = await fetch(
			`${apiEndpoint}/register`,
			this.#generateOptions('PUT', {
				first_name: firstName,
				last_name: lastName,
				profile_display_name: username,
				email_provided: email,
				password: password
			})
		);

		const result = {
			success: false,
			message: 'Error connecting to server.',
		}

		if (response.ok) {
			const data = await response.json();
			if (data.success) {
				try {
					this.#setProfile(data.profile);
					this.#setSession(data.session);
					result.success = true;
					result.message = 'Registered.';
				} catch (e) {
					result.message = e.message;
				}
			} else {
				result.message = data.message;
			}
		}
		
		this.#saveClientData();
		return result;
	}

	setScore = async (guid, mode, score, date) => {
		if(!this.authenticated) return {
			success: false,
			message: 'Not logged in.',
		}

		const response = await fetch(
			`${apiEndpoint}/set_score`,
			this.#generateOptions('POST', {
				id: guid,
				mode: mode,
				score: score,
				date: date,
			})
		);

		const result = {
			success: false,
			message: 'Error connecting to server.',
		}

		if (response.ok) {
			const data = await response.json();
			if (data.success) {
				result.success = true;
				result.message = 'Score sent';
			} else {
				result.message = data.message;
			}
		} else {
			if(response.status == 403) {
				this.#notLoggedIn();
				result.message = 'Not logged in';
			}
		}

		return result;	
	}

	updateProfile = async () => {}
	viewProfile = async () => {}
}