/*
Properties:
	columns - An array of arrays of cards, where each card contains:
		color - The primary color for the card, e.g. red, black
		rank - The card rank, e.g. K, Q, J, 10, 9, etc.
		icon - The icon for the suit, e.g. ♠, ♥, etc.
		value - The card's value, e.g. 9, 10, 11, 12 for 9, 10, J, Q
	display - [none, icon, rank, value] What to render in the center of the card
	onPress - The function to call when the column is pressed
	selected - An array of booleans identifying if a column is selected
	width - The width of the cards in percent, e.g. 12.5 for 1/8th or 12.5% of the screen
*/

import { StyleSheet, View } from 'react-native';
import { Column } from './Column';

export const Columns = (props) => {
	const columns = props.columns || [];
	const display = props.display || 'icon';
	const onPress = typeof (props.onPress) == 'function' ? props.onPress : () => {/*Do nothing*/ };
	const selected = props.selected || [];
	const width = props.width || 12.5;
	
	if(columns.length == 0) columns.push([],[],[],[]);

	const styles = StyleSheet.create({
		columnsContainer: {
			display: 'flex',
			flex: 1,
			flexDirection: 'row',
			justifyContent: 'space-around',
			width: '100%',
		},
	});
	
	return (
		<View style={styles.columnsContainer}>
			{columns.map((cards, i) => (
				<Column 
					key={ i }
					cards = { cards }
					display={ display } 
					width={ width }
					onPress={ () => { onPress(i); }}
					selected={ selected[i] }
				/>
			))}
		</View>
	);
};

export default Columns;