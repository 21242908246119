import { useEffect, useState } from 'react';

/** useAppModal **/

export const useAppModal = () => {
	const defaultState = {
		visible: false,
		title: '',
		text: '',
		buttons: [],
		prompt: false,
	};

	const [modalState, setModalState] = useState(defaultState);

	const _createConfirm = async (title, text, func, type) => {
		show(title, text, [
			{
				text: 'Cancel',
				type: 'muted',
				onPress: () => { hide(); func(false); },
			},
			{
				text: 'OK',
				type: type,
				onPress: () => { hide(); func(true); },
			},
		]);
	};

	const alert = async (title, text, func) => {
		if(!text && !title) throw new Error('Alert must have text or a title.')
		show(title, text, [
			{
				text: 'OK',
				type: 'success',
				onPress: () => { hide(); if(func) func();},
			},
		]);
	};

	const confirm = async (title, text, func) => {
		if((!text && !title) || !func) throw new Error('Confirm must have text or a title and a function.');
		_createConfirm(title, text, func, 'success');
	};

	const confirmDanger = async (title, text, func) => {
		if((!text && !title) || !func) throw new Error('Confirm must have text or a title and a function.');
		_createConfirm(title, text, func, 'danger');
	};

	const prompt = async (title, text, func) => {
		if((!text && !title) || !func) throw new Error('Prompt must have text or a title and an a function.')
		show(title, text, [
			{
				text: 'Cancel',
				type: 'muted',
				onPress: () => { hide(); func(false); },
			},
			{
				text: 'OK',
				type: 'success',
				onPress: (value) => { hide(); func(value); },
			},
		], true);
	};

	const hide = () => {
		setModalState(defaultState);
	};

	const show = (title, text, buttons, prompt) => {
		setModalState({
			visible: true,
			title: title,
			text: text,
			buttons: buttons || [],
			prompt: prompt === true,
		});
	};

	return {
		modalState,
		modalController: {
			alert,
			confirm,
			confirmDanger,
			prompt,
			hide,
			show,
		}
	}
};

export default useAppModal;