import React, { useContext } from 'react';
import { ImageBackground, SafeAreaView, ScrollView, StyleSheet, Text, View } from "react-native";
import { Columns, Equation, NavigationButton } from '../components';
import { Context } from '../../context';
import scaler from '../ui_utils/useScaler';
import backgroundImage from '../../assets/game_background.png';

export const Game = ({ navigation }) => {
	const { gameState, gameController, modalController } = useContext(Context);

	const getDealButtonLabel = () => {
		if (gameState.rowsRemaining == 1) return 'Last Deal';
		if (gameState.rowsRemaining <= 0) return 'Done';
		return 'Deal';
	};

	const requestNewGame = () => {
		if(gameState.gameOver) {
			gameController.newGame();
		} else {
			modalController.confirmDanger(
				'Start a new game?',
				'If you click "OK" you will lose any progress in your current game.',
				(result)=>{
					if(result) gameController.newGame();
				}
			);
		}
	}

	const widths = {
		3: { equation: 16, column: 20 },
		4: { equation: 16, column: 20 },
		5: { equation: 14, column: 17 },
		6: { equation: 12, column: 15 }
	};

	const buttonWidth = 24.5;

	const styles = StyleSheet.create({

		rowPadding: {
			padding: scaler.fontSize * 0.5,
		},

		rowStatus: {
			backgroundColor: 'lightgrey',
			borderRadius: scaler.fontSize * 0.25,
			height: scaler.pageHeight * 0.04,
			padding: scaler.fontSize * 0.5,
			opacity: 0.75,
			width: scaler.getWidth(96),
		},

		rowButtons: {
			height: scaler.pageHeight * 0.06,
			padding: scaler.fontSize * 0.5,
		},

		rowEquation: {
			height: scaler.pageHeight * 0.175,
			padding: scaler.fontSize * 0.5,
		},

		rowGameOver: {
			display: gameState.gameOver ? 'flex' : 'none',
			height: scaler.pageHeight * 0.06,
			padding: scaler.fontSize * 0.5,
		},

		rowColumns: {
			height: scaler.pageHeight * 0.6,
			justifyContent: 'flex-start',
			overflow: 'scroll',
			padding: scaler.fontSize * 0.5,
			width: '100%',
		},

		statusMode: {
			color: 'black',
			fontSize: scaler.fontSize * 1.6,
			fontWeight: '600',
		},

		statusText: {
			color: 'black',
			fontSize: scaler.fontSize * 1.25,
			fontWeight: '600',
		},

	});

	return (
		<View style={scaler.styles.window}>
			<ImageBackground source={backgroundImage} style={scaler.styles.image}>
				<SafeAreaView style={[scaler.styles.page, scaler.styles.transparent]}>

					<View style={scaler.styles.column}>

							<View style={[scaler.styles.row, styles.rowStatus]}>
								<Text style={styles.statusText}>Score: {gameState.score}</Text>
								<Text style={styles.statusMode}>{gameState.mode}</Text>
								<Text style={styles.statusText}>Rows left: {gameState.rowsRemaining}</Text>
							</View>

							<View style={[scaler.styles.row, styles.rowButtons]}>
								<NavigationButton
									text="Home"
									type='muted'
									onPress={() => {
										navigation.navigate('Home');
									}}
									width={buttonWidth}
								/>

								<NavigationButton
									text="New Game"
									type='muted'
									onPress={() => {
										requestNewGame();
									}}
									width={buttonWidth}
								/>

								<NavigationButton
									text={getDealButtonLabel()}
									type='muted'
									disabled={!gameState.canDeal}
									onPress={() => {
										gameController.dealNextRow();
									}}
									width={buttonWidth}
								/>

								<NavigationButton
									text={gameState.gameOver ? 'Game Over' : 'Clear'}
									type={gameState.gameOver ? 'danger' : 'muted'}
									disabled={!gameState.canClear}
									onPress={() => {
										gameController.clear();
									}}
									width={buttonWidth}
								/>
							</View>

							<View style={[scaler.styles.row, styles.rowEquation]}>
								<Equation
									display="value"
									side={gameState.side}
									left={gameState.left}
									right={gameState.right}
									onPressLeft={(i) => {
										gameController.toggleEquation('left');
									}}
									onPressRight={(i) => {
										gameController.toggleEquation('right');
									}}
									width={widths[gameState.columnCount].equation}
								/>
							</View>

							<View style={[scaler.styles.row]}>
							<View style={[styles.rowColumns]}>
								<Columns
									columns={gameState.columns}
									display="value"
									onPress={(i) => {
										if(gameState.gameOver) return;
										gameController.toggleColumn(i);
									}}
									selected={gameState.selected}
									width={widths[gameState.columnCount].column}
								/>
							</View>
							</View>

						</View>


				</SafeAreaView>
			</ImageBackground>
		</View>
	);
}

export default Game;