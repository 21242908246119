import { generateGuid } from '../../utils';
import { decks } from '../../definitions';
import Card from './card';

const generateCardList = (deck) => {
	const cardList = {};
	const order = [];

	deck.suits.forEach((suit) => {
		deck.ranks.forEach((rank, i) => {
			const guid = generateGuid();
			cardList[guid] = new Card({suit : suit, rank : rank, value : deck.values[i]});
			order.push(guid);
		});
	});

	if(deck.add) deck.add.forEach((card)=>{
		const guid = generateGuid();
		cardList[guid] = new Card({suit: card.suit, rank: card.rank, value: card.value});
		order.push(guid);
	});

	Object.freeze(cardList);

	return [cardList, order];
};

export default class Deck {
	#deck = false;
	#cards = false;
	#order = false;
	#cardPointer = -1;

	constructor (deckType) {
		this.#deck = decks[deckType];
		if(!this.#deck) throw new Error(`Invalid Deck Type "${deckType}"`);
		[this.#cards, this.#order] = generateCardList(this.#deck);
		this.cardPointer = -1;
	};

	cardsDealt = () => {
		return this.#cardPointer + 1;
	};

	cardsRemaining = () => {
		return this.deckSize() - this.cardsDealt();
	};

	currentCard = () => {
		if (this.#cardPointer < 0) return null;
		return this.#cards[this.#order[this.#cardPointer]];
	};

	deckSize = () => {
		return this.#order.length;
	};

	deal = () => {
		this.#cardPointer++;
		if (this.#cardPointer >= this.#order.length) {
			this.#cardPointer = this.#order.length - 1;
			return false;
		}
		return this.currentCard();
	};

	getDeckType = () => {
		return this.#deck.name;
	};

	getDeckName = () => {
		return this.#deck.display;
	};

	shuffle = () => {
		const orig = this.#order.map(x => x);
		this.#order.length = 0;
		while (orig.length > 0) {
			const randomIndex = Math.floor(Math.random() * orig.length);
			this.#order.push(orig.splice(randomIndex, 1)[0]);
		}
		this.#cardPointer = -1;
		return true;
	};
};