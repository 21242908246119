import { useEffect, useState } from 'react';
import { Game } from './game_engine/';

let _game = false;

export const useGameEngine = (engine, scoreController) => {
	const [gameState, setGameState] = useState({});

	useEffect(() => {
		updateGameState();
	}, []);

	const _setScore = async () => {
		if(!_game) return;
	
		const mode = _game.gameMode;
		const guid = _game.guid;
		const score = _game.score;
	
		await scoreController.setScore(guid, mode, score);
	};

	const clear = async () => {
		if(!_game) return;		
		_game.clear();
		if(_game.cardsRemaining / _game.deckSize < 0.5) await _setScore();
		updateGameState();
	};

	const dealNextRow = async () => {
		if(!_game) return;
		_game.dealNextRow();
		if(_game.cardsRemaining / _game.deckSize < 0.5) await _setScore();
		updateGameState();
	};

	const newGame = (gameMode) => {
		const mode = gameMode || (_game ? _game.gameMode : 'standard');
		_game = new Game(mode);
		updateGameState();
	};

	const toggleColumn = (index) => {
		if(!_game) return;
		_game.toggleColumn(index);
		updateGameState();
	};

	const toggleEquation = (side) => {
		if(!_game) return;
		_game.toggleEquation(side);
		updateGameState();
	};

	const updateGameState = () => {
		if(!_game) {
			setGameState({
				canClear : false,
				canDeal : false,
				canResume : false,
				columns : [[],[],[],[]],
				columnCount : 4,
				left : [],
				right : [],
				gameOver : true,
				guid : false,
				hasValidMove: false,
				mode : 'No Game',
				score : 0,
				selected : [false, false, false, false],
				side : 'left',
				rowsRemaining : 0
			});
			return true;
		}
	
		const columns = [];
		const selected = [];
		_game.columns.forEach((column)=>{
			const cards = [];
			selected.push(column.selected);
			column.cards.forEach((card)=>{
				cards.push(card.getState());
			});
			columns.push(cards);
		});
	
		const left = [];
		_game.equation.left.forEach((card) => {
			left.push(card.getState());
		});
	
		const right = [];
		_game.equation.right.forEach((card) => {
			right.push(card.getState());
		});

		const canClear = left.length > 0 || right.length > 0;
		const gameOver = _game.rowsRemaining < 1 && !_game.hasValidMove;
		const canDeal = !gameOver && !canClear && _game.rowsRemaining > 0;
		
		setGameState({
			canClear : canClear,
			canDeal : canDeal,
			canResume : !gameOver,
			columns : columns,
			columnCount : columns.length,
			left : left,
			right : right,
			gameOver : gameOver,
			guid : _game.guid,
			hasValidMove: _game.hasValidMove,
			mode : _game.gameName,
			score : _game.score,
			selected : selected,
			side : _game.equation.side,
			rowsRemaining : _game.rowsRemaining
		});
	};

	return {
		gameState,
		gameController : {
			clear,
			dealNextRow,
			newGame,
			toggleColumn,
			toggleEquation,
			updateGameState,
		}
	}
};

export default useGameEngine;