import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { 
	Game, 
	Home, 
	HowToPlay, 
	Login, 
	Logout, 
	OneTimePasscode, 
	Profile, 
	Register 
} from './ui/pages/';
import packageJson from './package.json';
import { Context } from './context';
import { AppEngine, useClient, useGameEngine, useScoreManager } from './engine';
import { AppModal, useAppModal } from './ui/app_modal';

const Stack = createNativeStackNavigator();
const engine = new AppEngine();

const App = () => {
	const {scoreState, scoreController} = useScoreManager(engine);
	const {clientState, clientController} = useClient(engine, scoreController);
	const {gameState, gameController} = useGameEngine(engine, scoreController);
	const {modalState, modalController} = useAppModal();
	const version = packageJson.version;

	const context = {
		clientState, clientController,
		scoreState, scoreController,
		gameState, gameController,
		modalState, modalController,
		version,
	};

	return (
		<Context.Provider value={context}>
			<AppModal 
				title={modalState.title}
				text={modalState.text}
				visible={modalState.visible}
				buttons={modalState.buttons}
				prompt={modalState.prompt}
			/>
			<NavigationContainer>
				<Stack.Navigator>
					<Stack.Screen
						name="Home"
						component={Home}
						options={{ headerShown: false, title: 'Tairedown™ | Home' }}
					/>

					<Stack.Screen
						name="Game"
						component={Game}
						options={{ headerShown: false, title: 'Tairedown™'  }}
					/>
					
					<Stack.Screen
						name="HowToPlay"
						component={HowToPlay}
						options={{ headerShown: false, title: 'Tairedown™ | How to Play'  }}
					/>

					<Stack.Screen
						name="Register"
						component={Register}
						options={{ headerShown: false, title: 'Tairedown™ | Register'  }}
					/>
					<Stack.Screen
						name="Login"
						component={Login}
						options={{ headerShown: false, title: 'Tairedown™ | Login'  }}
					/>
					<Stack.Screen
						name="Logout"
						component={Logout}
						options={{ headerShown: false, title: 'Tairedown™ | Logout'  }}
					/>
					<Stack.Screen
						name="Profile"
						component={Profile}
						options={{ headerShown: false, title: 'Tairedown™ | Profile'  }}
					/>
					<Stack.Screen
						name="OneTimePasscode"
						component={OneTimePasscode}
						options={{ headerShown: false, title: 'Tairedown™ | One Time Passcode'  }}
					/>
				</Stack.Navigator>
			</NavigationContainer>
		</Context.Provider>
	);
};

export default App;