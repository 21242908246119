import { useEffect, useState } from 'react';

export const useClient = (engine, scoreController) => {
	const client = engine.client;
	const [clientState, setClientState] = useState({});

	let _lastError = false;
	let _authenticated = false;
	let _profileId = false;
	
	useEffect(() => {
		_initClient();
	}, []);

	const _initClient = async () => {
		await client.loadClientData();
		updateClientState();
	};
	
	const updateClientState = () => {

		setClientState({
			authenticated: client.authenticated,
			lastError: _lastError,
			profile: client.profile,
			session: client.session,
			sessionKey: client.sessionKey,
		});

		if(client.authenticated != _authenticated || client.profile.id != _profileId) {
			_authenticated = client.authenticated;
			_profileId = client.profile.id;
			engine.profileIdChanged();
			scoreController.profileIdChanged(_profileId);
		}
	};

	const changePassword = async (password) => {
		const result = await client.changePassword(password);
		_lastError = result.success ? false : result.message;
		updateClientState();
	}

	const clearError = async () => {
		_lastError = false;
		updateClientState();
	};

	const login = async (username, password) => {
		const result = await client.login(username, password);
		_lastError = result.success ? false : result.message;
		updateClientState();
		return result.success;
	}

	const logout = () => {
		client.logout();
		updateClientState();
		return true;
	}

	const register = async (username, password, firstName, lastName, email) => {
		const result = await client.register(username, password, firstName, lastName, email);
		_lastError = result.success ? false : result.message;
		updateClientState();
		return result.success;
	}

	const registerProfileChangeHook = (hook) => {
		_profileChangeHooks.push(hook);
	};

	const setScore = async (guid, mode, score, date) => {
		const result = await client.setScore(guid, mode, score, date);
		_lastError = result.success ? false : result.message;
		updateClientState();
		return result.success;
	}

	const updateProfile = async () => {
		updateClientState();
	}

	const viewProfile = async () => {
		updateClientState();
	}

	return {
		clientState,
		clientController: {
			changePassword,
			clearError,
			login,
			logout,
			register,
			registerProfileChangeHook,
			setScore,
			updateClientState,
			updateProfile,
			viewProfile,
		}
	}
};

export default useClient;