import React, { useContext, useEffect, useState } from 'react';
import { SafeAreaView, Text, TextInput, View } from "react-native";
import { NavigationButton } from '../components';
import { Context } from '../../context';
import scaler from '../ui_utils/useScaler';

export const Login = ({ navigation }) => {
	const { clientState, clientController } = useContext(Context);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [waiting, setWaiting] = useState(false);

	useEffect(() => {
		clientController.clearError();
	}, []);

	const handleLogin = async () => {
		setWaiting(true);
		const success = await clientController.login(username, password);
		setPassword('');
		setWaiting(false);
		if(success) navigation.navigate('Home');
	};

	/*

						<Text 
							disabled={waiting}
							style={scaler.styles.textLink}
							onPress={() => { navigation.navigate('OneTimePasscode'); }}
						>
							Forgot password?
						</Text>
	*/

	return (
		<View style={scaler.styles.window}>
			<SafeAreaView style={scaler.styles.page}>
				<View style={scaler.styles.column}>

					<Text style={scaler.styles.textTitle}>Tairedown™</Text>
					<Text style={scaler.styles.textSubtitle}>Login</Text>
					<View style={scaler.styles.form}>
						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>Username</Text>
							<TextInput
								onChangeText={setUsername}
								placeholder="Username"
								readOnly={waiting}
								style={scaler.styles.formInput}
								value={username}
							/>
						</View>

						<View style={scaler.styles.formRow}>
							<Text style={scaler.styles.formLabel}>Password</Text>
							<TextInput
								onChangeText={setPassword}
								placeholder="Password"
								readOnly={waiting}
								secureTextEntry
								style={scaler.styles.formInput}
								value={password}
							/>
						</View>

						<Text style={scaler.styles.textError}>{clientState.lastError ? `${clientState.lastError}` : ``}</Text>

						<Text 
							disabled={waiting}
							style={scaler.styles.textLink}
							onPress={() => { navigation.navigate('Register'); }}
						>
							Need to signup?
						</Text>
					</View>

					<View style={scaler.styles.row}>
						<NavigationButton
							disabled={waiting}
							onPress={() => { navigation.navigate('Home'); }}
							text="Home"
							type='muted'
						/>
						<NavigationButton
							disabled={waiting || !username || !password}
							onPress={handleLogin}
							text="Login"
							type='success'
						/>
					</View>
				</View>
			</SafeAreaView>
		</View>
	);
}

export default Login;